import {Checkbox, Col, Form, Input, Row, Select, Switch} from 'antd';
import {useQuery} from 'react-query';
import api from '../../api';
import {greetings} from '../../constants';
import {formatDate} from '../../utils/normalize';

const {Option} = Select;

const MemberForm = ({form, onFinish, extraFields, itemData}) => {
  const {data: {data: statuses = []} = {}} = useQuery(['statuses'], () => {
    return api.meetings.statusList()
  }, {
    cacheTime: Infinity,
  });
  const {data: {data: states = []} = {}} = useQuery(['states'], () => {
    return api.meetings.stateList()
  }, {
    cacheTime: Infinity,
  });
  const {data: {data: specialPrograms = []} = {}} = useQuery(['specialPrograms', extraFields['meeting']], () => {
    return api.meetings.meetingSpecialProgramList({meeting: extraFields['meeting']})
  }, {
    cacheTime: Infinity,
  });
  const {data: {data: accompanyPrograms = []} = {}} = useQuery(['accompanyPrograms', extraFields['meeting']], () => {
    return api.meetings.meetingAccompanyProgramList({meeting: extraFields['meeting']})
  }, {
    cacheTime: Infinity,
  });
  const {data: {data: eveningPrograms = []} = {}} = useQuery(['eveningPrograms', extraFields['meeting']], () => {
    return api.meetings.meetingEveningProgramList({meeting: extraFields['meeting']})
  }, {
    cacheTime: Infinity,
  });
  const {data: {data: parkingPass = []} = {}} = useQuery(['parkingPass', extraFields['meeting']], () => {
    return api.meetings.meetingParkingPassList({meeting: extraFields['meeting']})
  }, {
    cacheTime: Infinity,
  });

  const FieldList = ({title, list, fieldName, type}) => {
    let dt = '';
    return <>
      {list.length > 0 ? <h3 className="strong">{title}</h3> : ''}
      <Form.List name={fieldName}>
        {fields => (<div className="mb-20">
          {list?.map((item, index) => {
              let pDate = '';
              if (dt !== item.date_program) {
                pDate = item.date_program;
                dt = item.date_program;
              }
              return <div key={index}>
                <b>{pDate && formatDate(pDate, 'YYYY-MM-DD', 'dddd, DD.MM.YYYY')}</b>
                <SpecItem type={type} key={index} index={index} item={item}/>
              </div>
            })}
        </div>)}
      </Form.List>
    </>
  }

  const SpecItem = ({type, item, index}) => {
    return <><Row>
      <Form.Item key={`${type}_${item.id}`} hidden initialValue={item.id}
                 name={[index, type]}><Input/></Form.Item>
      <Col span={12}>
        <Form.Item key={`${type}_is_enable_${item.id}`}
                   valuePropName="checked" name={[index, 'is_enable']}
                   initialValue={false} style={{marginBottom: 0}}>
          <Checkbox>{item.info}</Checkbox>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item key={`${type}_is_member_${item.id}`}
                   valuePropName="checked" name={[index, 'is_member']}
                   initialValue={false} style={{marginBottom: 0}}>
          <Checkbox>Teilnehmer</Checkbox>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item key={`${type}_is_partner_${item.id}`}
                   valuePropName="checked" name={[index, 'is_partner']}
                   initialValue={false} style={{marginBottom: 0}}>
          <Checkbox>Begleitperson</Checkbox>
        </Form.Item>
      </Col>
    </Row>

      {
        type === 'accompany_program' && itemData && itemData.accompany_programs && itemData.accompany_programs.map(
          i => {
            return item.id === i.accompany_program && i.additional ?
              <Col span={24} style={{marginBottom: 20}}>
                <b>Personenbezogene Daten der Teilnehmer</b><br/>
                {i.additional?.firstname1} {i.additional?.lastname1},  {i.additional?.date1}, {i.additional?.place1} <br/>
                {i.additional?.firstname2} {i.additional?.lastname2},  {i.additional?.date2}, {i.additional?.place2}
              </Col>
             : ''
          }
        )
      }

    </>;
  };


  return (
    <Form
      form={form}
      name="MemberForm"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      layout="horizontal"
      onFinish={onFinish}
      scrollToFirstError={true}
    >
      <Row>
        <Col span={12}>
          <Form.Item label="Teilnehmerstatus" name="status" rules={[{required: true}]}>
            <Select>
              {statuses?.map(item => <Option key={item.id}
                                             value={item.id}>{item.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Teilnahme bestätigen"
                     valuePropName="checked" name="is_active"
                     initialValue={true}>
            <Switch/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Anrede" name="greeting" rules={[{required: true}]}>
            <Select>
              {greetings?.map(item => <Option key={item.value}
                                              value={item.value}>{item.label}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Titel" name="title">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Firma" name="company">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Name" name="last_name" rules={[{required: true}]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Vorname" name="first_name" rules={[{required: true}]}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Straße, Nr" name="address" rules={[{required: true}]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="PLZ" name="post_index" rules={[{required: true}]}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Ort" name="area" rules={[{required: true}]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Bundesland" name="state">
            <Select>
              {states.map(item => <Option key={item.id}
                                          value={item.id}>{item.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Telefon" name="phone">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Fax" name="fax">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="E-mail" name="email" rules={[{required: true}]}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <hr className="hr"/>
      <Row>
        <Col span={12}>
          <Form.Item label="Gebuchtes Hotel" name="hotel">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <hr className="hr"/>
      <h3 className="strong">Begleitperson</h3>
      <Row>
        <Col span={12}>
          <Form.Item label="Anrede" name="partner_greeting">
            <Select>
              {greetings?.map(item => <Option key={item.value}
                                              value={item.value}>{item.label}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Titel" name="partner_title">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Nachname" name="partner_last_name">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Vorname" name="partner_first_name">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <hr className="hr"/>
      <FieldList list={specialPrograms} title="Fachprogramm"
                 fieldName="special_programs" type="special_program"/>

      <FieldList list={eveningPrograms} title="Abendprogramm"
                 fieldName="evening_programs" type="evening_program"/>

      <FieldList list={accompanyPrograms} title="Begleitprogramm"
                 fieldName="accompany_programs" type="accompany_program"/>

      <FieldList list={parkingPass} title="Parkausweis"
                 fieldName="parking_passes" type="parking_pass"/>
    </Form>
  );
}

export default MemberForm;
