export const timesOfDay = [
  {value: 'morning', label: 'Vormittag'},
  {value: 'afternoon', label: 'Nachmittag'},
  {value: 'all_day', label: 'Ganztag'},
];

export const greetings = [
  {value: 'Frau', label: 'Frau'},
  {value: 'Herr', label: 'Herr'},
];

