import {Alert, Button} from 'antd';
import {useMutation, useQuery} from 'react-query';
import api from '../../api';
import {queryClient} from '../../utils/react-query';


const InvoiceSendingOneForm = ({parentId, parentName, setVisible}) => {
  const {data: {data: itemData = []} = {}} = useQuery(['invoice', parentId],
    async () => {
      return await api.invoice.invoiceRetrieve(parentId);
    }, {
      enabled: !!parentId,
      refetchInterval: 10000,
      cacheTime: Infinity,
    });

  const {mutateAsync: mutateAsyncUpdate} = useMutation(
    api.invoice.invoiceSend, {
      onSuccess: async () => {
        await queryClient.removeQueries(['invoice', parentId]);
      }
    });

  const startSending = async () => {
    await mutateAsyncUpdate({invoiceId: parentId});
  }

  return <div>
    <h3 className="title">E-Mail schicken</h3>
    <hr className="hr"/>
    {itemData.sending_status === 'CREATED' &&
      <Button style={{marginBottom: '20px'}} onClick={startSending}>
        Rechnungen verschicken
      </Button>
    }

    {itemData.sending_status === 'READY' &&
      <>
        <Alert message="Rechnungen können versendet werden" type="info"
               style={{marginBottom: '20px'}}/>
      </>
    }

    {itemData.sending_status === 'PROCESSING' &&
      <>
        <Alert message="Rechnungen in Bearbeitung" type="info"
               style={{marginBottom: '20px'}}/>
      </>
    }

    {itemData.sending_status === 'COMPLETED' &&
      <>
        <Alert message="Rechnungen wurden verschickt" type="success"
               style={{marginBottom: '20px'}}/>
        <Button style={{marginBottom: '20px'}} onClick={startSending}>
        Rechnungen erneut versenden
        </Button>
      </>
    }

    <hr className="hr"/>

  </div>
}

export default InvoiceSendingOneForm;