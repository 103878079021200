import {Menu} from 'antd';
import {Link} from 'react-router-dom';

const TopMenu = ({items}) => {
  return <Menu mode="horizontal">
    {
      items.map(item => {
        let el = item.name;
        if ('onClick' in item) {
          el = <a href="#0" onClick={item.onClick}>{item.name}</a>;
        } else if ('url' in item) {
          el = <Link to={item.onClick}>{item.name}</Link>;
        }
        return <Menu.Item key={item.key}>{el}</Menu.Item>;
      })
    }
  </Menu>;
}

export default TopMenu;