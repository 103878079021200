import {Form, Input, Button} from 'antd';

import {useAuth, formErrorHandler} from '../../utils';

const LoginForm = () => {
  const {login} = useAuth();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      await login(values);
    } catch (e) {
      formErrorHandler(e, form);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form form={form}
          style={{marginTop: 90}}
          name="basic"
          labelCol={{span: 10}}
          wrapperCol={{span: 4}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
    >
      <Form.Item
        label="Login"
        name="username"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>

      <Form.Item wrapperCol={{offset: 10, span: 16,}}>
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginForm;