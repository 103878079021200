import api from '../../api';
import {Button} from 'antd';
import {useMutation, useQueryClient} from 'react-query';

const ConfirmationCreateForm = ({parentId, parentName, setVisible, itemData}) => {
  const queryClient = useQueryClient();
  
  const {mutateAsync: mutateAsyncUpdate} = useMutation(
    api.confirmation.confirmationCreate, {
      onSuccess: async () => {
        setVisible(false);
        await queryClient.invalidateQueries('confirmation-list');
      }
    });

  const startCreateConfirmation = async () => {
    await mutateAsyncUpdate({meetingId: parentId});
  }

  return <div><h3 className="title">Teilnehmerbestätigung erstellen</h3>
  <hr className="hr"/>
  <Button style={{marginBottom: '20px'}} onClick={startCreateConfirmation}>
  Teilnehmerbestätigung erstellen
</Button>
</div>
}

export default ConfirmationCreateForm;