import {Route, Routes} from "react-router-dom";
import GuestRoute from "../components/GuestRoute";

import PrivateRoute from "../components/PrivateRoute";
import Confirmation from "../containers/Confirmation";
import Invitation from '../containers/Invitation';
import Invoice from '../containers/Invoice';

import Login from "../containers/Login";
import MeetingControl from '../containers/MeetingControl';
import Member from '../containers/Member';
import MemberStatus from '../containers/MemberStatus';
import NotFound from '../containers/NotFound';
import Reports from '../containers/Reports';
import States from '../containers/State';

const AppRoutes = () => {
  return <Routes>
    <Route path="/login" element={<GuestRoute><Login/></GuestRoute>}/>
    <Route path="/" element={<PrivateRoute><Member/></PrivateRoute>}/>
    <Route path="/invitation" element={<PrivateRoute><Invitation/></PrivateRoute>}/>
    <Route path="/invoice" element={<PrivateRoute><Invoice/></PrivateRoute>}/>
    <Route path="/confirmation" element={<PrivateRoute><Confirmation/></PrivateRoute>}/>
    <Route path="/member_statuses" element={<PrivateRoute><MemberStatus/></PrivateRoute>}/>
    <Route path="/states" element={<PrivateRoute><States/></PrivateRoute>}/>
    <Route path="/meetings-control" element={<PrivateRoute><MeetingControl/></PrivateRoute>}/>
    <Route path="/reports" element={<PrivateRoute><Reports/></PrivateRoute>}/>
    <Route path="*" element={<NotFound/>}/>
  </Routes>;
}

export default AppRoutes;
