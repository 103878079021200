import axios from '../axios';

const endpoints = {
  memberList: (params) => axios.get(`/members/members/`, {params}),
  memberRetrieve: id => axios.get(`/members/members/${id}/`),
  memberCreate: params => axios.post('/members/members/', params),
  memberUpdate: ({id, ...params}) => axios.put(`/members/members/${id}/`, params),
  memberDelete: id => axios.delete(`/members/members/${id}/`),
  memberCount: (params) => axios.get(`/members/member-count/`, {params}),
  memberSendEmail: id => axios.post(`/members/members/${id}/resent_email/`),
};

export default endpoints;