import {message} from 'antd';
import {useEffect} from 'react';

export const useErrorEffect = ({form, updatedId, errorUpdate, errorCreate}) => {
    useEffect(() => {
        const error = updatedId ? errorUpdate : errorCreate;
        if (error && error.response.data) {
            error.response.data.non_field_errors?.map(item =>
              message.error(item)
            );
            if (error.response.data.detail) {
                message.error(error.response.data.detail);
            }
            for (const [key, value] of Object.entries(error.response.data)) {
                form.setFields([
                    {
                        name: key,
                        errors: value,
                    },
                ]);
            }
        } else {
            if (error && error.response) {
                message.error('Auf dem Server ist ein Fehler aufgetreten.');
                console.log('Serverfehler:', error.response);
            }
        }
    }, [errorUpdate, errorCreate, form, updatedId]);
}
