import {Space} from 'antd';
import {
  SmileOutlined
} from '@ant-design/icons';

import {useNavigate} from 'react-router-dom';

import SiderLayout from '../../components/SiderLayout';
import TopMenu from '../../components/TopMenu';
import {useAuth} from '../../utils';
import {menuLinks} from './mainMenu';

import {LogoComponent, footerText} from './index';

const MainLayout = ({breadCrumbs = [], children}) => {
  const {user, logout} = useAuth();
  const navigate = useNavigate();

  const onLogOut = () => {
    logout();
    navigate('/login');
  };

  const topMenu = [
    {key: 'logout', onClick: onLogOut, name: 'Logout'},
  ];

  const TopMenuComponent = () => <div style={{marginLeft: 'auto'}}>
    <Space><b><SmileOutlined/> {user?.username}</b><TopMenu items={topMenu}/></Space>
  </div>;

  return (
    <SiderLayout
      sideMenuLinks={menuLinks}
      footerText={footerText}
      LogoComponent={LogoComponent}
      TopMenuComponent={TopMenuComponent}
      breadCrumbs={breadCrumbs}
    >{children}</SiderLayout>
  );
}

export default MainLayout;
