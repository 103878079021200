import CRUD from '../../components/CRUD';
import api from '../../api';
import StateForm from './StateForm';

const StateList = () => {
    const columns = [
        {
            title: 'Bundesland',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Ist aktiv',
            dataIndex: 'is_visible',
            key: 'is_b',
            width: '20%',
            render: (text) => (
                text ? 'Ja' : 'Nein'
            ),
            sorter: (a, b) => a.is_visible - b.is_visible,
        },
    ];

    return <CRUD
        keyName="state"
        title="Bundeslands"
        FormComponent={StateForm}
        tableColumns={columns}
        apiList={api.meetings.stateList}
        apiRetrieve={api.meetings.stateRetrieve}
        apiCreate={api.meetings.stateCreate}
        apiUpdate={api.meetings.stateUpdate}
        apiDelete={api.meetings.stateDelete}
    />
};

export default StateList;
