import {EllipsisOutlined} from '@ant-design/icons';
import {Button, Dropdown, Form, Menu, Modal, PageHeader, Space, Table,} from 'antd';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useErrorEffect} from '../../hooks';

const CRUD = ({
                keyName,
                title,
                FormComponent,
                tableColumns,
                apiList,
                apiRetrieve,
                apiCreate,
                apiUpdate,
                apiDelete,
                listRefetchInterval = Infinity,
                searchParams = {},
                itemName = 'datenbankeintrag',
                itemValueName = 'name',
                fieldForChildren = 'id',
                childrenActions,
                commonActions,
                parentId,
                parentName,
                parentField,
                parentFieldParamName,
                modalWidth = 900,
                normalizeFormData = data => data,
                normalizeSendData = data => data,
                extraFields = {},
              }) => {
  const itemKey = `${keyName}-item`;
  const listKey = `${keyName}-list`;
  parentFieldParamName = parentFieldParamName ? parentFieldParamName : parentField;

  const queryClient = useQueryClient();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleChildrenModal, setVisibleChildrenModal] = useState(false);
  const [visibleCommonModal, setVisibleCommonModal] = useState(false);
  const [actionKey, setActionKey] = useState(false);
  const [commonActionKey, setCommonActionKey] = useState(false);
  const [recordForChild, setRecordForChild] = useState();
  const [updatedId, setUpdatedId] = useState();

  const [deleteModal, deleteContextHolder] = Modal.useModal();

  const invalidateAfterMutation = async () => {
    await queryClient.invalidateQueries(listKey);
    // await queryClient.invalidateQueries(itemKey);
    queryClient.setQueriesData([itemKey], undefined);
  };

  const {mutateAsync: mutateAsyncUpdate, error: errorUpdate} = useMutation(
    apiUpdate, {
      onSuccess: async () => await invalidateAfterMutation(),
    });
  const {mutateAsync: mutateAsyncCreate, error: errorCreate} = useMutation(
    apiCreate, {
      onSuccess: async () => await invalidateAfterMutation(),
    });
  const {mutateAsync: mutateAsyncDelete} = useMutation(
    apiDelete, {
      onSuccess: () => queryClient.invalidateQueries(listKey),
    });

  const [form] = Form.useForm();

  const getMenu = (record) => (
    <Menu>
      {apiUpdate &&
      <Menu.Item key="update">
        <a href="#0" onClick={async () => {
          setUpdatedId(record.id);
          setVisibleModal(true);
        }}>Bearbeiten</a>
      </Menu.Item>
      }
      {apiDelete &&
      <Menu.Item key="delete">
        <a href="#0" onClick={
          () => deleteModal.confirm({
            title: 'Entfernung',
            content: `Wirklich löschen ${record[itemValueName]}?`,
            onOk: async () => await mutateAsyncDelete(record.id),
          })
        }>Löschen</a>
      </Menu.Item>
      }
      {childrenActions && Object.keys(childrenActions).map(actionKey => <Menu.Item
        key={actionKey}>
        <a href="#0" onClick={childrenActions[actionKey].onClick ? () => childrenActions[actionKey].onClick(record) : () => showChildren(actionKey, record)}>
          {childrenActions[actionKey].childrenActionName}
        </a>
      </Menu.Item>)}
    </Menu>
  );

  const columns = [
    ...tableColumns,
    {
      title: 'Aktionen',
      key: 'action',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={getMenu(record)} placement="bottomLeft"
                  arrow>
          <Button shape="circle"><EllipsisOutlined/></Button>
        </Dropdown>
      ),
    },
  ];

  const showChildren = async (actionKey, record) => {
    setRecordForChild(record);
    setVisibleChildrenModal(true);
    setActionKey(actionKey);
  };

 const showCommon = async (commonActionKey) => {
    setVisibleCommonModal(true);
    setCommonActionKey(commonActionKey);
  };

  const listParams = {}
  for (const [key, value] of Object.entries(searchParams)) {
    listParams[key] = value;
  }
  if (parentId) {
    listParams[parentFieldParamName] = parentId;
  }

  const {data: {data: listData = []} = {}} = useQuery([listKey, listParams],
    () => {
      return apiList(listParams);
    }, {
      cacheTime: Infinity,
      refetchInterval: listRefetchInterval,
    });

  const {data: {data: itemData = []} = {}} = useQuery([itemKey, updatedId],
    async () => {
      return await apiRetrieve(updatedId);
    }, {
      enabled: !!updatedId,
      cacheTime: Infinity,
    });

  const cancelHandler = async () => {
    form.resetFields();
    setVisibleModal(false);
    setUpdatedId(undefined);
    // await queryClient.invalidateQueries(itemKey);
    // queryClient.setQueriesData([itemKey], undefined);
  };

  const submitHandler = async (values) => {
    const normalValues = normalizeSendData(values);
    if (parentField && parentId) {
      normalValues[parentField] = parentId;
    }
    if (updatedId) {
      await mutateAsyncUpdate({id: updatedId, ...normalValues, ...extraFields});
    } else {
      await mutateAsyncCreate({...normalValues, ...extraFields});
    }
    setVisibleModal(false);
    setUpdatedId(undefined);
    form.resetFields();
  };

  useErrorEffect({form, updatedId, errorUpdate, errorCreate});

  useEffect(() => {
    if (itemData) {
      form.setFieldsValue(normalizeFormData(itemData));
    }
  }, [itemData, form, normalizeFormData]);

  const ChildrenCRUD = actionKey ? childrenActions[actionKey].ChildrenCRUD : undefined;
  const ActionComponent = commonActionKey ? commonActions[commonActionKey].ActionComponent : undefined;

  return <>
    <Modal
      forceRender
      destroyOnClose={true}
      title={updatedId
        ? `Bearbeiten ${itemName}: ${itemData[itemValueName]}`
        : `Addieren ${itemName}`}
      visible={visibleModal}
      onOk={() => {
        form.submit();
      }}
      onCancel={cancelHandler}
      width={modalWidth}
    >
      <FormComponent
        form={form}
        onFinish={submitHandler}
        itemData={itemData}
        parentFieldParamName={parentFieldParamName}
        parentId={parentId}
        extraFields={extraFields}
      />
    </Modal>

    <Modal
      forceRender
      destroyOnClose={true}
      visible={visibleChildrenModal}
      onCancel={() => setVisibleChildrenModal(false)}
      width={actionKey && childrenActions[actionKey] && childrenActions[actionKey].width ? childrenActions[actionKey].width : '90%'}
      footer=""
    >
      {recordForChild &&
        <ChildrenCRUD parentId={recordForChild[fieldForChildren]}
                      parentName={recordForChild[itemValueName]}
                      setVisible={setVisibleChildrenModal}/>}
    </Modal>

    <Modal
      forceRender
      destroyOnClose={true}
      visible={visibleCommonModal}
      onCancel={() => setVisibleCommonModal(false)}
      width={commonActionKey && commonActions[commonActionKey] && commonActions[commonActionKey].width ? commonActions[commonActionKey].width : '90%'}
      footer=""
    >
      {ActionComponent && <ActionComponent setVisible={setVisibleCommonModal} {...commonActions[commonActionKey]?.extraProps}/>}
    </Modal>

    {deleteContextHolder}

    {title && <PageHeader
      className="site-page-header"
      title={title}
      subTitle={parentName}
    />}

    <Space className="grid-nav">
      {apiCreate && <Button key="add" type="primary" onClick={() => setVisibleModal(true)}>
        Addieren
      </Button> }
      {commonActions && Object.keys(commonActions).map((slug) => {
        const item = commonActions[slug];
        return <Button key={slug} type={item.buttonType ? item.buttonType : 'primary'}
                       onClick={() => showCommon(slug)} {...item.buttonExtraProps}>
          {item.buttonLabel}
        </Button>
      })}
    </Space>

    <Table
      columns={columns}
      dataSource={listData}
      rowKey="id"
      size="small"
      bordered/>
  </>;
};

export default CRUD;
