import {Alert, Button} from 'antd';
import {useMutation, useQuery} from 'react-query';
import api from '../../api';
import {queryClient} from '../../utils/react-query';


const ConfirmationSendingOneForm = ({parentId, parentName, setVisible}) => {
  const {data: {data: itemData = []} = {}} = useQuery(['confirmation', parentId],
    async () => {
      return await api.confirmation.confirmationRetrieve(parentId);
    }, {
      enabled: !!parentId,
      refetchInterval: 10000,
      cacheTime: Infinity,
    });

  const {mutateAsync: mutateAsyncUpdate} = useMutation(
    api.confirmation.confirmationSend, {
      onSuccess: async () => {
        await queryClient.removeQueries(['confirmation', parentId]);
      }
    });

  const startSending = async () => {
    await mutateAsyncUpdate({confirmationId: parentId});
  }

  return <div>
    <h3 className="title">E-Mail schicken</h3>
    <hr className="hr"/>
    {itemData.sending_status === 'CREATED' &&
      <Button style={{marginBottom: '20px'}} onClick={startSending}>
        Teilnehmerbestätigung verschicken
      </Button>
    }

    {itemData.sending_status === 'READY' &&
      <>
        <Alert message="Teilnehmerbestätigung können versendet werden" type="info"
               style={{marginBottom: '20px'}}/>
      </>
    }

    {itemData.sending_status === 'PROCESSING' &&
      <>
        <Alert message="Teilnehmerbestätigung in Bearbeitung" type="info"
               style={{marginBottom: '20px'}}/>
      </>
    }

    {itemData.sending_status === 'COMPLETED' &&
      <>
        <Alert message="Teilnehmerbestätigung wurden verschickt" type="success"
               style={{marginBottom: '20px'}}/>
        <Button style={{marginBottom: '20px'}} onClick={startSending}>
        Teilnehmerbestätigung erneut versenden
        </Button>
      </>
    }

    <hr className="hr"/>

  </div>
}

export default ConfirmationSendingOneForm;