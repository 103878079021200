import api from '../../api';
import {Button} from 'antd';
import {useMutation, useQueryClient} from 'react-query';

const InvoiceCreateForm = ({parentId, parentName, setVisible, itemData}) => {
  const queryClient = useQueryClient();
  
  const {mutateAsync: mutateAsyncUpdate} = useMutation(
    api.invoice.invoiceCreate, {
      onSuccess: async () => {
        setVisible(false);
        await queryClient.invalidateQueries('invoice-list');
      }
    });

  const startCreate = async () => {
    await mutateAsyncUpdate({meetingId: parentId});
  }

  return <div><h3 className="title">Rechnungen erstellen</h3>
  <hr className="hr"/>
  <Button style={{marginBottom: '20px'}} onClick={startCreate}>
  Rechnungen erstellen
</Button>
</div>
}

export default InvoiceCreateForm;