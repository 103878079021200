import {SearchOutlined} from '@ant-design/icons';
import {Button, Input, Modal, Space} from 'antd';
import moment from 'moment';
import {useMutation} from 'react-query';
import api from '../../api';
import CRUD from '../../components/CRUD';
import {formatDate} from '../../utils/normalize';
import MemberForm from './MemberForm';

const MemberList = ({searchParams}) => {
  let searchInput;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => (selectedKeys, confirm, dataIndex) => confirm()}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined
      style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });


  const columns = [
    {
      title: 'Frau/Herr',
      dataIndex: 'greeting',
      key: 'greeting',
    },
    {
      title: 'Titel',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Nachname',
      dataIndex: 'last_name',
      key: 'last_name',
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Vorname',
      dataIndex: 'first_name',
      key: 'first_name',
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Begleitperson',
      dataIndex: 'partner_last_name',
      key: 'partner_last_name',
      render: (text, row) => {
        return row.partner_last_name ?
          `${row.partner_last_name}, ${row.partner_first_name}` : ''
      },
      ...getColumnSearchProps('partner_last_name'),
    },
    {
      title: 'PLZ',
      dataIndex: 'post_index',
      key: 'post_index',
      sorter: (a, b) => a.post_index - b.post_index,
    },
    {
      title: 'Ort',
      dataIndex: 'area',
      key: 'area',
      sorter: (a, b) => a.area.localeCompare(b.area),
    },
    {
      title: 'Bundesland',
      dataIndex: 'state_name',
      key: 'state_name',
      sorter: (a, b) => a.state_name.localeCompare(b.state_name),
    },
    {
      title: 'Hotel',
      dataIndex: 'hotel',
      key: 'hotel',
    },
    {
      title: 'Anmeldung',
      dataIndex: 'date_create',
      key: 'date_create',
      render: (text) => {
        return formatDate(text);
      },
      sorter: (a, b) => moment(a.date_create).unix() - moment(b.date_create).unix(),
    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status_name',
      width: '20%',
      sorter: (a, b) => a.status_name.localeCompare(b.status_name),
    },
    {
      title: 'Teiln.',
      dataIndex: 'cnt',
      key: 'cnt',
      render: (text, row) => (
        row.partner_last_name ? '2' : '1'
      )
    },
  ];

  const normalizeFormData = data => {
    if (data.id) {
      if (data.special_programs.length === 0) {
        delete data.special_programs;
      }
      if (data.accompany_programs.length === 0) {
        delete data.accompany_programs;
      }
      if (data.evening_programs.length === 0) {
        delete data.evening_programs;
      }
      if (data.parking_passes.length === 0) {
        delete data.parking_passes;
      }
    }
    return data;
  }

  const {mutateAsync: memberSendEmail} = useMutation(api.member.memberSendEmail);

  return <CRUD
    keyName="member"
    itemName="teilnehmer"
    itemValueName="last_name"
    modalWidth="90%"
    FormComponent={MemberForm}
    tableColumns={columns}
    apiList={api.member.memberList}
    apiRetrieve={api.member.memberRetrieve}
    apiCreate={api.member.memberCreate}
    apiUpdate={api.member.memberUpdate}
    apiDelete={api.member.memberDelete}
    searchParams={searchParams}
    extraFields={{meeting: searchParams['meeting']}}
    normalizeFormData={normalizeFormData}
    childrenActions={{
      'resendEmail': {
        childrenActionName: 'E-Mail erneut senden',
        onClick: (record) => Modal.confirm({
            title: 'E-Mail erneut senden',
            content: `Senden ${record.last_name}?`,
            onOk: async () => await memberSendEmail(record.id),
          }),
      }
    }}
  />
}

export default MemberList;