import {Navigate, useLocation} from 'react-router-dom';

import {useAuth} from '../../utils';

function PrivateRoute({children}) {
  const {user} = useAuth();
  const location = useLocation();
  const url = new URLSearchParams();
  url.set('redirect', location.pathname + location.search);

  return user ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        search: url.toString(),
      }}
    />
  );
}

export default PrivateRoute;
