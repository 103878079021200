import {Button, Form, Input, Space} from 'antd';
import {useEffect} from 'react';
import {useMutation, useQuery} from 'react-query';

import api from '../../api';
import RichEditor from '../../components/RichEditor';
import {useErrorEffect} from '../../hooks';
import {queryClient} from '../../utils/react-query';


const MeetingEmailTemplateForm = ({parentId, parentName, setVisible}) => {
  const [form] = Form.useForm();

  const {data: {data: itemData = []} = {}} = useQuery(['emailTpl', parentId],
    async () => {
      return await api.meetings.meetingControlRetrieve(parentId);
    }, {
      enabled: !!parentId,
      cacheTime: Infinity,
    });

  useEffect(() => {
    if (itemData) {
      form.setFieldsValue(itemData);
    }
  }, [itemData, form]);

  const {mutateAsync: mutateAsyncUpdate, error: errorUpdate} = useMutation(
    api.meetings.meetingControlPartialUpdate, {
      onSuccess: async () => {
        await queryClient.removeQueries(['emailTpl', parentId]);
      }
    });

  const submitHandler = async (values) => {
    await mutateAsyncUpdate({id: parentId, ...values});
    await queryClient.invalidateQueries(['emailTpl', parentId]);
    form.resetFields();
    setVisible(false);
  }

  useErrorEffect({form, parentId, errorUpdate});

  return <div>
    <h3 className="title">Einladungsvorlage {itemData.name}</h3>
    <hr className="hr"/>
    <Form form={form}
          name="emailTplForm"
          onFinish={submitHandler}
          labelCol={{span: 5}}
          wrapperCol={{span: 19}}
    >
      <Form.Item label="Theme" name="email_subject" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="E-Mail-Vorlage für"
                 name="email_template" rules={[{required: true}]}>
        <RichEditor initialValue={itemData.email_template}/>
      </Form.Item>
      <Form.Item label="PDF-Vorlage für"
                 name="pdf_template" rules={[{required: false}]}>
        <RichEditor initialValue={itemData.pdf_template}/>
      </Form.Item>
    </Form>
    <hr className="hr"/>
    <div style={{textAlign: 'right'}}>
      <Space>
        <Button onClick={() => setVisible(false)}>Abbrechen</Button>
        <Button type="primary" onClick={() => form.submit()}>OK</Button>
      </Space>
    </div>

  </div>;
};

export default MeetingEmailTemplateForm;