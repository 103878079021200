import {Menu} from 'antd';
import {Link, useLocation} from 'react-router-dom';

const SideMenu = ({menuLinks}) => {
  const {SubMenu} = Menu;
  const location = useLocation();
  const basePath = location.pathname.split('/').slice(0, 3).join('/');

  const getSelectedKeys = (items, pathname) => {
    let selectedKeys = [];
    items.map(item => {
      if (item.url === pathname) {
        selectedKeys.push(item.name ? item.name : item.url);
      }
      if (item.subItems) {
        const itemKeys = getSelectedKeys(item.subItems, pathname);
        if (itemKeys.length) {
          selectedKeys.push(item.name);
        }
      }
      return null;
    });
    return selectedKeys;
  }

  const renderItem = (item) => {
    if (item.subItems) {
      return <SubMenu key={item.name} icon={item.icon} title={item.name}>
        {item.subItems.map(subItem => <Menu.Item key={subItem.url}>
            <Link to={subItem.url}>{subItem.name}</Link>
          </Menu.Item>
        )}
      </SubMenu>;
    } else {
      return <Menu.Item key={item.url} icon={item.icon}>
        <Link to={item.url}>{item.name}</Link>
      </Menu.Item>;
    }
  }

  return <Menu theme="dark"
               defaultSelectedKeys={basePath}
               defaultOpenKeys={getSelectedKeys(menuLinks, basePath)}
               mode="inline">
    {menuLinks.map(item => renderItem(item))}
  </Menu>;
}

export default SideMenu;
