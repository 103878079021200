import {Modal} from 'antd';
import api from '../../api';
import CRUD from '../../components/CRUD';
import {formatDate} from '../../utils/normalize';
import MeetingEmailTemplateForm from '../MeetingControl/MeetingEmailTemplateForm';
import InvitationForm from './InvitationForm';
import InvitationSendingForm from './InvitationSendingForm';
import InvitationUpload from './InvitationUpload';

const InvitationList = ({searchParams}) => {

function info(row) {
  Modal.info({
    title: 'Error',
    content: (
      <div>
        <p>{row.error}</p>
      </div>
    ),
    onOk() {},
  });
}

  const columns = [
    {
      title: 'Frau/Herr',
      dataIndex: 'greeting',
      key: 'greeting',
    },
    {
      title: 'Titel',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Nachname',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Vorname',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Teilnehmerstatus',
      dataIndex: 'status_name',
      key: 'status_name',
      sorter: (a, b) => a.status_name.localeCompare(b.status_name),
    },
    {
      title: 'Versandstatus',
      dataIndex: 'is_success',
      key: 'is_success',
      render: ((text, row) => {
        if (text === false || text === true) {
          return text ? 'Erfolg' : <a onClick={()=>info(row)}>Error</a>;
        }
      }),
      sorter: (a, b) => a - b,
    },
    {
      title: 'Versanddatum',
      dataIndex: 'date_sent',
      key: 'date_sent',
      render: (text => {
        return text ? formatDate(text, 'YYYY-MM-DD HH:mm:ss', 'DD.MM.YYYY HH:mm:ss') : ''
      }),
      sorter: (a, b) => a.date_sent > b.date_sent,
    },
  ];

  return <>
    <CRUD
    keyName="invitation"
    title="Einladungen"
    FormComponent={InvitationForm}
    tableColumns={columns}
    searchParams={searchParams}
    itemValueName="last_name"
    apiList={api.invitation.invitationList}
    listRefetchInterval={10000}
    apiRetrieve={api.invitation.invitationRetrieve}
    apiCreate={api.invitation.invitationCreate}
    apiUpdate={api.invitation.invitationUpdate}
    apiDelete={api.invitation.invitationDelete}
    extraFields={{meeting: searchParams['meeting']}}
    childrenActions={{
      'pdf': {
        childrenActionName: 'PDF',
        onClick: (record) => window.open(process.env.REACT_APP_API_URL + '/invitation/invitation-pdf/' + record.id, '_blank'),
      }
    }}
    commonActions={{
      'upload': {
        ActionComponent: InvitationUpload,
        buttonLabel: 'Listenimport',
        buttonType: 'secondary',
        extraProps: {
          meeting: searchParams['meeting'],
        },
        width: 500,
      },
      'template': {
        ActionComponent: MeetingEmailTemplateForm,
        buttonLabel: 'Einladungsvorlage',
        buttonType: 'secondary',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'sending': {
        ActionComponent: InvitationSendingForm,
        buttonLabel: 'E-Mail schicken',
        buttonType: 'secondary',
        extraProps: {
          meeting: searchParams['meeting'],
        },
        width: 700,
      },
    }}
  /></>
};

export default InvitationList;
