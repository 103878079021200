import {DownloadOutlined} from '@ant-design/icons';
import {Button, Space} from 'antd';
import api from '../../api';

const ReportsLinks = ({searchParams}) => {

  const getMembersCsv = e => {
    api.report.membersCsv({
      filename: `members-${searchParams.meeting}.csv`,
      params: {
        meeting: searchParams.meeting,
      }
    });
  }

  const getProgramMembersCsv = e => {
    api.report.programMembersCsv({
      filename: `members-${searchParams.meeting}.csv`,
      params: {
        meeting: searchParams.meeting,
        special_program: searchParams.specialProgram,
        accompany_program: searchParams.accompanyProgram,
        evening_program: searchParams.eveningProgram
      }
    });
  }

  const getMembers = e => {
    api.report.membersPdf({
      filename: `members-${searchParams.meeting}.pdf`,
      params: {
        meeting: searchParams.meeting,
      }
    });
  }

  const getReport = e => {
    api.report.listOfSigns({
      filename: `unterschriftenliste-${searchParams.meeting}.pdf`,
      params: {
        meeting: searchParams.meeting,
        special_program: searchParams.specialProgram,
        accompany_program: searchParams.accompanyProgram,
        evening_program: searchParams.eveningProgram
      }
    });
  }

  const getBadges = e => {
    api.report['badges']({
      filename: `namensschilder-${searchParams.meeting}.pdf`,
      params: {
        meeting: searchParams.meeting,
        special_program: searchParams.specialProgram
      }
    });
  }

  return <div>
    <hr className="hr"/>
    <div style={{marginBottom: '20px'}}><Space>
      <Button shape="round" icon={<DownloadOutlined/>} onClick={getReport}>
        Unterschriftenliste {searchParams.specialProgram && 'Begleitprogramm'}
      </Button>
      <Button shape="round" icon={<DownloadOutlined/>} onClick={getBadges}>
        Namensschilder {searchParams.specialProgram && 'Begleitprogramm'}
      </Button>
      <Button shape="round" icon={<DownloadOutlined/>} onClick={getMembers}>
        Teilnehmer
      </Button>
      <Button shape="round" icon={<DownloadOutlined/>} onClick={getMembersCsv}>
        CSV {searchParams.specialProgram}
      </Button>
      <Button shape="round" icon={<DownloadOutlined/>} onClick={getProgramMembersCsv}>
        Programm CSV
      </Button>
    </Space></div>
    <hr className="hr"/>
  </div>;
}

export default ReportsLinks;