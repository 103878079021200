import axios from '../axios';

const getPDF = url => ({filename = 'confirmation.pdf', params}) => axios({
  url: url,
  method: 'GET',
  params: params,
  responseType: 'blob',
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
});

const endpoints = {
  confirmationList: (params) => axios.get(`/confirmation/confirmation/`, {params}),
  confirmationRetrieve: id => axios.get(`/confirmation/confirmation/${id}/`),
  confirmationDelete: id => axios.delete(`/confirmation/confirmation/${id}/`),
  confirmationCreate: ({meetingId}) => axios.post(`/confirmation/create/${meetingId}/`),
  confirmationPDF: id => getPDF(`/confirmation/pdf/${id}`),
  confirmationAllPDF: getPDF(`/confirmation/pdf2/`),
  confirmationSend: ({confirmationId}) => axios.patch(`/confirmation/send/${confirmationId}/`),
};

export default endpoints;