import axios from '../axios';

const endpoints = {
    loginWithUsernameAndPassword:
      async (params = {}) => await axios.post('/auth/login/', {...params}),
    getUserProfile:
      async () => await axios.get('/auth/current/'),
    logout:
      async () => await axios.post('/auth/logout/'),
};

export default endpoints;
