import axios from '../axios';

const endpoints = {
  upload: (filename, data) => axios.post(`/upload/${filename}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};

export default endpoints;
