import {useState} from 'react';
import {MainLayout} from '../Common';
import ConfirmationList from './ConfirmationList';
import ConfirmationSearch from './ConfirmationSearch';

const State = () => {
  const [searchParams, setSearchParams] = useState({});

  const breadCrumbs = [
    {key: 'confirmation', name: 'Teilnehmerbestätigung'}
  ];

  return <MainLayout breadCrumbs={breadCrumbs}>
    <ConfirmationSearch searchParams={searchParams} setSearchParams={setSearchParams}/>
    {searchParams.meeting && <ConfirmationList searchParams={searchParams}/>}
  </MainLayout>;
};

export default State;