import api from '../../api';
import {Button} from 'antd';

const ConfirmationPDF = ({parentId, parentName, setVisible}) => {

  return <div><h3 className="title">Herunterladen PDF</h3>
  <hr className="hr"/>
  <Button style={{marginBottom: '20px'}} onClick={api.confirmation.confirmationPDF(parentId)}>
  Herunterladen PDF
</Button>
</div>
}

export default ConfirmationPDF;