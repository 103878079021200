import {Form, Select} from 'antd';
import {useQuery} from 'react-query';
import api from '../../api';

const MemberSearch = ({searchParams = {}, setSearchParams}) => {

  const [form] = Form.useForm();

  const {data: {data: meetings = []} = {}} = useQuery(
    ['search-member-meeting'],
    () => {
      return api.meetings.meetingList();
    }, {
      cacheTime: Infinity,
      onSuccess: ({data}) => {
        const {id: value} = data[0];
        setSearchParams({...searchParams, meeting: value});
        form.setFieldsValue({
          meeting: data[0].id
        });
      }
    });

  const {data: {data: states = []} = {}} = useQuery(
    ['search-member-state'],
    () => {
      return api.meetings.stateList();
    }, {
      cacheTime: Infinity,
    });

  const {data: {data: statuses = []} = {}} = useQuery(
    ['search-member-status'],
    () => {
      return api.meetings.statusList();
    }, {
      cacheTime: Infinity,
    });

  const {data: {data: specialPrograms = []} = {}} = useQuery(
    ['search-special_program', searchParams.meeting],
    () => {
      return api.meetings.meetingSpecialProgramList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });

  const {data: {data: eveningPrograms = []} = {}} = useQuery(
    ['search-evening_program', searchParams.meeting],
    () => {
      return api.meetings.meetingEveningProgramList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });

  const {data: {data: accompanyPrograms = []} = {}} = useQuery(
    ['search-accompany_program', searchParams.meeting],
    () => {
      return api.meetings.meetingAccompanyProgramList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });


  const paramChangeHandler = (param) => (value) => {
    setSearchParams({...searchParams, [param]: value});
    return undefined;
  }

  return <Form
    form={form}
    name="search"
    labelCol={{span: 4}}
    wrapperCol={{span: 18}}
  >
    <Form.Item label="Tagung" name="meeting">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.meeting}
        onChange={paramChangeHandler('meeting')}
      >
        {meetings.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>

    <Form.Item label="Bundesland" name="state">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.state}
        onChange={paramChangeHandler('state')}
        allowClear={true}
      >
        {states.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item label="Teilnehmerstatus" name="status">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.status}
        onChange={paramChangeHandler('status')}
        allowClear={true}
      >
        {statuses.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item label="Fachprogramm" name="special_program">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.special_program}
        onChange={paramChangeHandler('special_program')}
        allowClear={true}
      >
        {specialPrograms.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item label="Abendprogramm" name="evening_program">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.evening_program}
        onChange={paramChangeHandler('evening_program')}
        allowClear={true}
      >
        {eveningPrograms.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item label="Begleitprogramm" name="accompany_program">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.accompany_program}
        onChange={paramChangeHandler('accompany_program')}
        allowClear={true}
      >
        {accompanyPrograms.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>
  </Form>;
}

export default MemberSearch;