import {DatePicker, Form, Input, InputNumber, Select, Switch} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {timesOfDay} from '../../constants';

const {Option} = Select;

const MeetingAccompanyProgramForm = ({form, onFinish}) => {
  return (
    <Form
      form={form}
      name="meetingAccompanyProgram"
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Begleitprogramm" name="name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Untertitel" name="subtitle">
        <Input/>
      </Form.Item>
      <Form.Item label="Datum" name="date_program" rules={[{required: true}]}>
        <DatePicker format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item label="Tageszeit" name="times_of_day">
        <Select allowClear>
          {timesOfDay.map(item => <Option value={item.value}>{item.label}</Option>)}
          <Option value="afternoon">Nachmittag</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Auswahl Teilnehmer" valuePropName="checked"
                 name="selection_of_members" initialValue={false}>
        <Switch/>
      </Form.Item>
      <Form.Item label='Unkostenbeitrag' initialValue={0} rules={[{required: true}]}
                 name="price"><InputNumber addonAfter="€" style={{width: 100}}/>
      </Form.Item>
      <Form.Item label="Kontaktdaten erforderlich" valuePropName="checked"
                 name="is_need_contacts" initialValue={false}>
        <Switch/>
      </Form.Item>
      <Form.Item label="Beschreibung" name="description">
        <TextArea rows={5}/>
      </Form.Item>
      <Form.Item label="Maximale Teilnehmerzahl" name="max_members">
        <InputNumber/>
      </Form.Item>
      <Form.Item label='Sortierung' initialValue={0} rules={[{required: true}]}
                 name="sort_num"><InputNumber/>
      </Form.Item>
    </Form>
  );
};

export default MeetingAccompanyProgramForm;
