import api from '../../api';
import {Button} from 'antd';

const InvoicePDF = ({parentId, parentName, setVisible}) => {

  return      <div><h3 className="title">Herunterladen PDF</h3>
  <hr className="hr"/>
  <Button style={{marginBottom: '20px'}} onClick={api.invoice.invoicePDF(parentId)}>
  Herunterladen PDF
</Button>
</div>
}

export default InvoicePDF;