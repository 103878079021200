import React from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query';

export function initReactQueryAuth(config) {
  const AuthContext = React.createContext(null);
  AuthContext.displayName = 'AuthContext';

  const {
    loadUser,
    loginFn,
    logoutFn,
    key = 'auth-user',
    waitInitial = true,
    LoaderComponent = () => <div>Loading...</div>,
    ErrorComponent = (error) => (
      <div style={{color: '#C00'}}>{JSON.stringify(error, null, 2)}</div>
    ),
  } = config;

  function AuthProvider({children}) {
    const queryClient = useQueryClient();

    const {
      data: user,
      error,
      status,
      isLoading,
      isIdle,
      isSuccess,
      refetch,
    } = useQuery({
      queryKey: key,
      queryFn: loadUser,
    });

    const setUser = React.useCallback(
      (data) => {
        queryClient.setQueryData(key, data)
      },
      [queryClient]
    );

    const loginMutation = useMutation({
      mutationFn: loginFn,
      onSuccess: user => {
        setUser(user);
      },
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      onSuccess: () => {
        queryClient.clear();
      },
    });

    const value = React.useMemo(
      () => ({
        user,
        error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        logout: logoutMutation.mutateAsync,
        isLoggingOut: logoutMutation.isLoading,
      }),
      [
        user,
        error,
        refetch,
        loginMutation.mutateAsync,
        loginMutation.isLoading,
        logoutMutation.mutateAsync,
        logoutMutation.isLoading,
      ]
    );

    if (isSuccess || !waitInitial) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    }

    if (isLoading || isIdle) {
      return <LoaderComponent/>;
    }

    if (error) {
      return <ErrorComponent error={error}/>;
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return {AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth};
}