import {initReactQueryAuth} from './react-query-auth';
import api from '../api';
import {storage} from "./storage";

const handleUserResponse = async (response) => {
  const {data} = response;
  const {token, ...user} = data;
  storage.setToken(token);
  return user;
};

const loadUser = async () => {
  let user = null;
  if (storage.getToken()) {
    const {data} = await api.auth.getUserProfile();
    user = data;
  }
  return user;
};

const loginFn = async (data) => {
  const response = await api.auth.loginWithUsernameAndPassword(data);
  return await handleUserResponse(response);
};

const logoutFn = async () => {
  await storage.clearToken();
};

const authConfig = {
  loadUser,
  loginFn,
  logoutFn,
};

const {AuthProvider, AuthConsumer, useAuth} = initReactQueryAuth(authConfig);

export {AuthProvider, AuthConsumer, useAuth};