import auth from './endpoints/auth';
import invitation from './endpoints/invitation';
import invoice from './endpoints/invoice';
import confirmation from './endpoints/confirmation';
import meetings from './endpoints/meeting';
import member from './endpoints/member';
import report from './endpoints/report';
import upload from './endpoints/upload';

const api = {
  auth,
  meetings,
  member,
  report,
  invitation,
  upload,
  invoice,
  confirmation,
};

export default api;
