import axios from '../axios';

const endpoints = {
    statusList: () => axios.get(`/meetings/status/`),
    statusRetrieve: id => axios.get(`/meetings/status/${id}/`),
    statusCreate: params => axios.post('/meetings/status/', params),
    statusUpdate: ({id, ...params}) => axios.put(`/meetings/status/${id}/`, params),
    statusDelete: id => axios.delete(`/meetings/status/${id}/`),

    stateList: () => axios.get(`/meetings/state/`),
    stateRetrieve: id => axios.get(`/meetings/state/${id}/`),
    stateCreate: params => axios.post('/meetings/state/', params),
    stateUpdate: ({id, ...params}) => axios.put(`/meetings/state/${id}/`, params),
    stateDelete: id => axios.delete(`/meetings/state/${id}/`),

    meetingControlList: () => axios.get(`/meetings/meeting_control/`),
    meetingControlRetrieve: id => axios.get(`/meetings/meeting_control/${id}/`),
    meetingControlCreate: params => axios.post('/meetings/meeting_control/', params),
    meetingControlUpdate: ({id, ...params}) => axios.put(`/meetings/meeting_control/${id}/`, params),
    meetingControlPartialUpdate: ({id, ...params}) => axios.patch(`/meetings/meeting_control/${id}/`, params),
    meetingControlDelete: id => axios.delete(`/meetings/meeting_control/${id}/`),

    meetingList: () => axios.get(`/meetings/meeting_list/`),

    meetingActivateSending: ({meetingId}) => axios.patch(`/meetings/activate_sending/${meetingId}/`),
    meetingInvoiceActivateSending: ({meetingId}) => axios.patch(`/meetings/invoice_activate_sending/${meetingId}/`),
    meetingConfirmationActivateSending: ({meetingId}) => axios.patch(`/meetings/confirmation_activate_sending/${meetingId}/`),

    meetingPlaceList: params => axios.get(`/meetings/place/`, {params}),
    meetingPlaceRetrieve: id => axios.get(`/meetings/place/${id}/`),
    meetingPlaceCreate: params => axios.post('/meetings/place/', params),
    meetingPlaceUpdate: ({id, ...params}) => axios.put(`/meetings/place/${id}/`, params),
    meetingPlaceDelete: id => axios.delete(`/meetings/place/${id}/`),

    meetingSpecialProgramSelectList: params => axios.get(`/meetings/special_program_list/`, {params}),
    meetingSpecialProgramList: params => axios.get(`/meetings/special_program/`, {params}),
    meetingSpecialProgramRetrieve: id => axios.get(`/meetings/special_program/${id}/`),
    meetingSpecialProgramCreate: params => axios.post('/meetings/special_program/', params),
    meetingSpecialProgramUpdate: ({id, ...params}) => axios.put(`/meetings/special_program/${id}/`, params),
    meetingSpecialProgramDelete: id => axios.delete(`/meetings/special_program/${id}/`),

    meetingAccompanyProgramList: params => axios.get(`/meetings/accompany_program/`, {params}),
    meetingAccompanyProgramRetrieve: id => axios.get(`/meetings/accompany_program/${id}/`),
    meetingAccompanyProgramCreate: params => axios.post('/meetings/accompany_program/', params),
    meetingAccompanyProgramUpdate: ({id, ...params}) => axios.put(`/meetings/accompany_program/${id}/`, params),
    meetingAccompanyProgramDelete: id => axios.delete(`/meetings/accompany_program/${id}/`),

    meetingEveningProgramList: params => axios.get(`/meetings/evening_program/`, {params}),
    meetingEveningProgramRetrieve: id => axios.get(`/meetings/evening_program/${id}/`),
    meetingEveningProgramCreate: params => axios.post('/meetings/evening_program/', params),
    meetingEveningProgramUpdate: ({id, ...params}) => axios.put(`/meetings/evening_program/${id}/`, params),
    meetingEveningProgramDelete: id => axios.delete(`/meetings/evening_program/${id}/`),

    meetingParkingPassList: params => axios.get(`/meetings/parking_pass/`, {params}),
    meetingParkingPassRetrieve: id => axios.get(`/meetings/parking_pass/${id}/`),
    meetingParkingPassCreate: params => axios.post('/meetings/parking_pass/', params),
    meetingParkingPassUpdate: ({id, ...params}) => axios.put(`/meetings/parking_pass/${id}/`, params),
    meetingParkingPassDelete: id => axios.delete(`/meetings/parking_pass/${id}/`),
};

export default endpoints;
