import {DatePicker, Form, Input, InputNumber, Switch} from 'antd';
import TextArea from 'antd/es/input/TextArea';

const MeetingEveningProgramForm = ({form, onFinish}) => {
  return (
    <Form
      form={form}
      name="meetingEveningProgram"
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Abendprogramm" name="name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Untertitel" name="subtitle">
        <Input/>
      </Form.Item>
      <Form.Item label="Datum" name="date_program" rules={[{required: true}]}>
        <DatePicker format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item label="Uhrzeit" name="time_program" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Auswahl Teilnehmer" valuePropName="checked"
                 name="selection_of_members" initialValue={false}>
        <Switch/>
      </Form.Item>
      <Form.Item label="Angabe Teilnehmerzahl" valuePropName="checked"
                 name="spec_num_of_members" initialValue={false}>
        <Switch/>
      </Form.Item>
      <Form.Item label='Unkostenbeitrag' initialValue={0} rules={[{required: true}]}
                 name="price"><InputNumber addonAfter="€" style={{width: 100}}/>
      </Form.Item>
      <Form.Item label="Beschreibung" name="description">
        <TextArea rows={5}/>
      </Form.Item>
      <Form.Item label="Maximale Teilnehmerzahl" name="max_members">
        <InputNumber/>
      </Form.Item>
      <Form.Item label='Sortierung' initialValue={0} rules={[{required: true}]}
                 name="sort_num"><InputNumber/>
      </Form.Item>
    </Form>
  );
};

export default MeetingEveningProgramForm;
