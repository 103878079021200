import {Col, Row} from 'antd';
import {useQuery, useMutation} from 'react-query';
import api from '../../api';

const MemberSummary = ({searchParams={}}) => {
  const {data: {data: members = []} = {}} = useQuery(
    ['member-list', searchParams],
    () => {
      return api.member.memberList(searchParams);
    }, {
      cacheTime: Infinity,
    });

  const {data: {data: evening_count = []} = {}} = useQuery(['getEveningCount', searchParams, members], () => api.member.memberCount({evening_program: searchParams.evening_program}));
  const {data: {data: special_count = []} = {}} = useQuery(['getSpecialCount', searchParams, members], () => api.member.memberCount({special_program: searchParams.special_program}));
  const {data: {data: accompany_count = []} = {}} = useQuery(['getAccompanyCount', searchParams, members], () => api.member.memberCount({accompany_program: searchParams.accompany_program}));
  return <><Row style={{marginBottom: 5}}>
    <Col span={12}>Summe der Anmeldungen: {members.length}</Col>
    <Col span={12} style={{textAlign: "right"}}>Summe der Teilnehmer:&nbsp; 
       {
        members.length > 0 && members.reduce((acc, obj) => {
          const i = obj.partner_last_name ? 2 : 1;
          return acc + i;
        }, 0)
      } 
    </Col>
  </Row>
  {searchParams.special_program && <Row style={{marginBottom: 20}}>
    <Col span={24} style={{textAlign: "right"}}>Summe der Teilnehmer Fachprogramm: {special_count.count}
      </Col>
  </Row>}
  {searchParams.evening_program && <Row style={{marginBottom: 20}}>
    <Col span={24} style={{textAlign: "right"}}>Summe der Teilnehmer Abendprogramm: {evening_count.count}
      </Col>
  </Row>}
  {searchParams.accompany_program && <Row style={{marginBottom: 20}}>
    <Col span={24} style={{textAlign: "right"}}>Summe der Teilnehmer Begleitprogramm: {accompany_count.count}
      </Col>
  </Row>}
  </>
  ;
}

export default MemberSummary;