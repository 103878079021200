import './static/styles/index.sass';

import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {ConfigProvider} from 'antd';
import deDE from 'antd/lib/locale/de_DE';

import AppRoutes from './routes';

import {ReactQueryProvider, AuthProvider} from './utils';

ReactDOM.render(
  <Router>
    <ConfigProvider locale={deDE}>
      <ReactQueryProvider>
        <AuthProvider>
          <AppRoutes/>
        </AuthProvider>
      </ReactQueryProvider>
    </ConfigProvider>
  </Router>,
  document.getElementById('root')
);
