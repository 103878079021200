import {DatePicker, Form, Input, InputNumber} from 'antd';

const MeetingPlaceForm = ({form, onFinish}) => {
    return (
        <Form
            form={form}
            name="meetingPlace"
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            layout="horizontal"
            onFinish={onFinish}
        >
            <Form.Item label="Ort" name="area" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Datum" name="date_meeting" rules={[{required: true}]}>
                <DatePicker format="DD.MM.YYYY"/>
            </Form.Item>
            <Form.Item label="Teilnehmerzahl" name="count_of_members" rules={[{required: true}]}>
                <InputNumber/>
            </Form.Item>
        </Form>
    );
};

export default MeetingPlaceForm;
