import {MainLayout} from '../../containers/Common';
import MemberStatusList from './MemberStatusList';

const MemberStatus = () => {
  const breadCrumbs = [
    {key: 'lists', name: 'Listen'},
    {key: 'statuses', name: 'Teilnehmerstatus'}
  ];
  return <MainLayout breadCrumbs={breadCrumbs}>
    <MemberStatusList/>
  </MainLayout>;
};

export default MemberStatus;