import {Form, Select} from 'antd';
import {useQuery} from 'react-query';
import api from '../../api';

const ConfirmationSearch = ({searchParams = {}, setSearchParams}) => {

  const [form] = Form.useForm();

  const {data: {data: meetings = []} = {}} = useQuery(
    ['search-member-meeting'],
    () => {
      return api.meetings.meetingList();
    }, {
      cacheTime: Infinity,
      onSuccess: ({data}) => {
        const {id: value} = data[0];
        setSearchParams({...searchParams, meeting: value});
        form.setFieldsValue({
          meeting: data[0].id
        });
      }
    });

  const paramChangeHandler = (param) => (value) => {
    setSearchParams({...searchParams, [param]: value});
    return undefined;
  }

  return <Form
    form={form}
    name="search"
    labelCol={{span: 4}}
    wrapperCol={{span: 18}}
  >
    <Form.Item label="Tagung" name="meeting">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.meeting}
        onChange={paramChangeHandler('meeting')}
      >
        {meetings.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>
  </Form>;
}

export default ConfirmationSearch;