import api from '../../api';
import CRUD from '../../components/CRUD';
import {
    formatDate,
    normalizeDateFields,
    normalizeOutDateFields
} from '../../utils/normalize';
import MeetingAccompanyProgramForm from './MeetingAccompanyProgramForm';


const MeetingSpecialProgramList = ({parentId, parentName}) => {
    const columns = [
        {
            title: 'Datum',
            dataIndex: 'date_program',
            key: 'date_program',
            render: (text => {
               return formatDate(text)
            }),
            sorter: (a, b) => a.date_program > b.date_program,
        },
        {
            title: 'Begleitprogramm',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Tageszeit',
            dataIndex: 'times_of_day_name',
            key: 'times_of_day_name'
        },
        {
            title: 'Kosten',
            dataIndex: 'price',
            key: 'price',
            render: (text => {
               return `${text} €`
            }),
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: 'Maximale Teilnehmerzahl',
            dataIndex: 'max_members',
            key: 'max_members',
        },
    ];

    const normalizeFormData = (data) => {
        return normalizeDateFields(data, ['date_program']);
    }

    const normalizeSendData = (data) => {
        return normalizeOutDateFields(data, ['date_program']);
    }

    return <CRUD
        keyName="meeting-accompany-program"
        title="Liste Begleitprogramme zur Tagung"
        FormComponent={MeetingAccompanyProgramForm}
        tableColumns={columns}
        apiList={api.meetings.meetingAccompanyProgramList}
        apiRetrieve={api.meetings.meetingAccompanyProgramRetrieve}
        apiCreate={api.meetings.meetingAccompanyProgramCreate}
        apiUpdate={api.meetings.meetingAccompanyProgramUpdate}
        apiDelete={api.meetings.meetingAccompanyProgramDelete}
        itemValueName="name"
        itemName="begleitprogramm"
        parentField="meeting"
        parentId={parentId}
        parentName={parentName}
        normalizeFormData={normalizeFormData}
        normalizeSendData={normalizeSendData}
    />
};

export default MeetingSpecialProgramList;
