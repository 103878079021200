import axios from '../axios';

const getReport = url => ({filename = 'file.pdf', params}) => axios({
  url: url,
  method: 'GET',
  params: params,
  responseType: 'blob',
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
});

const endpoints = {
  listOfSigns: getReport('/reports/list_of_signs/'),
  badges: getReport('/reports/badges/'),
  membersCsv: getReport('/reports/members-csv/'),
  membersPdf: getReport('/reports/members-pdf/'),
  programMembersCsv: getReport('/reports/program-members-csv/'),
};

export default endpoints;
