import {Navigate, useLocation} from 'react-router-dom';

import {useAuth} from '../../utils';

function GuestRoute({children}) {
  const {user} = useAuth();
  const location = useLocation();
  const url = new URLSearchParams(location.search.slice(1));

  return user ? <Navigate to={url.get('redirect') || '/'}/> : children;
}

export default GuestRoute;
