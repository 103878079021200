import {useState} from 'react';
import {MainLayout} from '../../containers/Common';
import InvitationList from './InvitationList';
import InvitationSearch from './InvitationSearch';

const State = () => {
  const [searchParams, setSearchParams] = useState({});

  const breadCrumbs = [
    {key: 'invitation', name: 'Einladungen'}
  ];

  return <MainLayout breadCrumbs={breadCrumbs}>
    <InvitationSearch searchParams={searchParams} setSearchParams={setSearchParams}/>
    {searchParams.meeting && <InvitationList searchParams={searchParams}/>}
  </MainLayout>;
};

export default State;