import api from '../../api';
import CRUD from '../../components/CRUD';
import {
    formatDate,
    normalizeDateFields,
    normalizeOutDateFields
} from '../../utils/normalize';
import MeetingAccompanyProgramList from './MeetingAccompanyProgramList';
import MeetingControlForm from './MeetingControlForm';
import MeetingEmailTemplate2Form from './MeetingEmailTemplate2Form';
import MeetingEmailTemplateForm from './MeetingEmailTemplateForm';
import MeetingEveningProgramList from './MeetingEveningProgramList';
import MeetingParkingPassList from './MeetingParkingPassList';
import MeetingPlaceList from './MeetingPlaceList';
import MeetingSpecialProgramList from './MeetingSpecialProgramList';

const MeetingControlList = () => {
    const columns = [
        {
            title: 'Datum von',
            dataIndex: 'date_from',
            key: 'date_from',
            render: (text => {
               return formatDate(text)
            }),
            sorter: (a, b) => a.date_from > b.date_from,
        },
        {
            title: 'Datum bis',
            dataIndex: 'date_to',
            key: 'date_to',
            render: (text => {
               return formatDate(text)
            }),
            sorter: (a, b) => a.date_to > b.date_to,
        },
        {
            title: 'Tagung',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Räumlichkeiten',
            dataIndex: 'place',
            key: 'place',
            sorter: (a, b) => a.place > b.place,
        },
        {
            title: 'Ort',
            dataIndex: 'area',
            key: 'area',
            sorter: (a, b) => a.area > b.area,
        },
        {
            title: 'Ist aktiv',
            dataIndex: 'is_visible',
            key: 'is_b',
            width: '20%',
            render: (text) => (
                text ? 'Ja' : 'Nein'
            ),
            sorter: (a, b) => a.is_visible - b.is_visible,
        },
    ];

    const normalizeFormData = (data) => {
        if (data['id']) data['set_statuses'] = data['statuses'];
        return normalizeDateFields(data,
          ['registration_latest_date', 'date_from', 'date_to']);
    }

    const normalizeSendData = (data) => {
        return normalizeOutDateFields(data,
          ['registration_latest_date', 'date_from', 'date_to']);
    }

    return <CRUD
        keyName="meeting-control"
        title="Tagungen verwalten"
        itemName="tagung"
        FormComponent={MeetingControlForm}
        tableColumns={columns}
        apiList={api.meetings.meetingControlList}
        apiRetrieve={api.meetings.meetingControlRetrieve}
        apiCreate={api.meetings.meetingControlCreate}
        apiUpdate={api.meetings.meetingControlUpdate}
        apiDelete={api.meetings.meetingControlDelete}
        normalizeSendData={normalizeSendData}
        normalizeFormData={normalizeFormData}
        childrenActions={{
            'place': {
                childrenActionName: 'Tagungsort zur Tagung',
                ChildrenCRUD: MeetingPlaceList,
            },
            'special_program': {
                childrenActionName: 'Fachprogramm zur Tagung',
                ChildrenCRUD: MeetingSpecialProgramList,
            },
            'accompany_program': {
                childrenActionName: 'Begleitprogramm zur Tagung',
                ChildrenCRUD: MeetingAccompanyProgramList,
            },
            'evening_program': {
                childrenActionName: 'Abendprogramm zur Tagung',
                ChildrenCRUD: MeetingEveningProgramList,
            },
            'parking_pass': {
                childrenActionName: 'Parkausweis zur Tagung',
                ChildrenCRUD: MeetingParkingPassList,
            },
            'email_template': {
                childrenActionName: 'Einladungsvorlage',
                ChildrenCRUD: MeetingEmailTemplateForm,
                width: 900,
            },
            'email_template2': {
                childrenActionName: 'Registrierungshinweis',
                ChildrenCRUD: MeetingEmailTemplate2Form,
                width: 900,
            },
        }}
    />
};

export default MeetingControlList;
