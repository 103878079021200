import {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Layout, Breadcrumb, Row, Col} from 'antd';

import SideMenu from '../SideMenu';

const {Header, Content, Footer, Sider} = Layout;

const SiderLayout = (
  {
    LogoComponent = () => <div className="logo"/>,
    TopMenuComponent = () => null,
    sideMenuLinks = [],
    siderWidth = 250,
    breadCrumbs = [],
    footerText='',
    children
  }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}
             width={siderWidth}>
        <LogoComponent/>
        <SideMenu menuLinks={sideMenuLinks}/>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background">
          <Row>
            <Col span={24} style={{display: 'flex'}}>
              <TopMenuComponent/>
            </Col>
          </Row>
        </Header>
        <Content style={{margin: '0 16px'}}>
          <Breadcrumb style={{margin: '16px 0'}}>
            {
              breadCrumbs.map(
                item => <Breadcrumb.Item key={item.key}>{
                  item.url ? <Link to={item.url}>{item.name}</Link> : item.name
                }</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
          <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
            {children}
          </div>
        </Content>
        <Footer style={{textAlign: 'center'}}>{footerText}</Footer>
      </Layout>
    </Layout>
  );
}

SiderLayout.propTypes = {
  LogoComponent: PropTypes.elementType,
  TopMenuComponent: PropTypes.elementType,
  sideMenuLinks: PropTypes.array,
  breadCrumbs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  })),
  footerText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export default SiderLayout;
