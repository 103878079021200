import {Alert, Button} from 'antd';
import {useMutation, useQuery} from 'react-query';
import api from '../../api';
import {queryClient} from '../../utils/react-query';


const InvitationSendingForm = ({...props}) => {
  const {data: {data: itemData = []} = {}} = useQuery(['meeting', props.meeting],
    async () => {
      return await api.meetings.meetingControlRetrieve(props.meeting);
    }, {
      enabled: !!props.meeting,
      refetchInterval: 10000,
      cacheTime: Infinity,
    });

  const {mutateAsync: mutateAsyncUpdate} = useMutation(
    api.meetings.meetingActivateSending, {
      onSuccess: async () => {
        await queryClient.removeQueries(['meeting', props.meeting]);
      }
    });

  const startSending = async () => {
    await mutateAsyncUpdate({meetingId: props.meeting});
  }

  return <div>
    <h3 className="title">E-Mail schicken</h3>
    <hr className="hr"/>
    {itemData.sending_status === 'CREATED' &&
      <Button style={{marginBottom: '20px'}} onClick={startSending}>
        Einladungen verschicken
      </Button>
    }

    {itemData.sending_status === 'READY' &&
      <>
        <Alert message="Einladungen können versendet werden" type="info"
               style={{marginBottom: '20px'}}/>
        <p>
          Es werden insgesamt <b>{itemData?.invitation_cnt}</b> Einladungen verschickt.
        </p>
      </>
    }

    {itemData.sending_status === 'PROCESSING' &&
      <>
        <Alert message="Einladungen in Bearbeitung" type="info"
               style={{marginBottom: '20px'}}/>
        <p>
          Es werden insgesamt <b>{itemData?.invitation_cnt}</b> Einladungen verschickt.
        </p>
      </>
    }

    {itemData.sending_status === 'COMPLETED' &&
      <>
        <Alert message="Einladungen wurden verschickt" type="success"
               style={{marginBottom: '20px'}}/>
        <Button style={{marginBottom: '20px'}} onClick={startSending}>
          Einladungen erneut versenden
        </Button>
      </>
    }

    <hr className="hr"/>

  </div>
}

export default InvitationSendingForm;