import {message} from "antd";

export const formErrorHandler = (e, form) => {
  if (!e.response) {
    message.error('Ошибка связи с сервером.');
  } else if (e.response.status === 404) {
    message.error('Page Not 404.');
  } else if (e.response.status === 403) {
    message.error('Forbidden.');
  } else if (e.response.status === 422 || e.response.status === 400) {
    e.response.data.non_field_errors.map(item =>
      message.error(item)
    );
    e.response.data.detail.map(item =>
      message.error(item)
    );
    Object.keys(e.response.data).forEach((key) => {
      form.setFields([
        {
          name: key,
          errors: [e.response.data[key]],
        },
      ]);
    });
  } else {
    message.error(`Error ${e.response.status}`);
  }
}

export const onValuesChange = form => values => {
  Object.keys(values).forEach(field => {
    const error = form.getFieldError(field);
    if (!error.length) {
      return;
    }
    // Clear error message of field
    form.setFields([
      {
        name: field,
        errors: []
      }
    ]);
  });
};