import {MainLayout} from '../../containers/Common';
import StateList from './StateList';

const State = () => {
  const breadCrumbs = [
    {key: 'lists', name: 'Listen'},
    {key: 'states', name: 'Bundeslands'}
  ];
  return <MainLayout breadCrumbs={breadCrumbs}>
    <StateList/>
  </MainLayout>;
};

export default State;