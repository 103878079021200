import MeetingControlList from './MeetingControlList';
import {MainLayout} from '../../containers/Common';

const MeetingControl = () => {
  const breadCrumbs = [
    {key: 'meeting-control', name: 'Tagungen verwalten'}
  ];

  return <MainLayout breadCrumbs={breadCrumbs}>
    <MeetingControlList/>
  </MainLayout>;
}

export default MeetingControl;
