import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

export const formatDate = (
    date, from='YYYY-MM-DD',
    to='DD.MM.YYYY') => moment(date, from).format(to);

export const normalizeDateFields = (instance, dateFields=[], timeFields=[]) => {
    const normalizedFields = instance;
    for (const field of dateFields) {
        normalizedFields[field] = instance[field] ? moment(instance[field]) : null;
    }
    for (const field of timeFields) {
        normalizedFields[field] = instance[field] ? moment(instance[field], 'HH:mm:ss') : null;
    }
    return normalizedFields;
}

export const normalizeOutDateFields = (instance, dateFields=[], timeFields=[]) => {
    const normalizedFields = instance;
    for (const field of dateFields) {
        normalizedFields[field] = instance[field] ? moment(instance[field]).format('YYYY-MM-DD') : null;
    }
    for (const field of timeFields) {
        normalizedFields[field] = instance[field] ? moment(instance[field]).format('HH:mm:ss') : null;
    }
    return normalizedFields;
}