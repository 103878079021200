import {message, Modal, Upload} from 'antd';
import {useState} from 'react';
import {useQueryClient} from 'react-query';
import api from '../../api';
import {storage} from '../../utils';

const InvitationUpload = ({...props}) => {
  const token = storage.getToken();
  const [infoResponse, setInfoResponse] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const onChange = async info => {
    setInfoResponse(info.file.response);
    if (info.file.status === 'done') {
      message.success(`${info.file.name} datei erfolgreich hochgeladen`);
      await queryClient.invalidateQueries('invitation-list');
    }
  };

  return <div>
    <Modal title="Fehler" visible={isModalVisible} footer="" onCancel={
      ()=>setIsModalVisible(false)
    }>
      {
        infoResponse && infoResponse.error_list.map(item => <p key={item[0]}>Reihe {item[0]}: {item[1]}</p>)
      }
    </Modal>

    <h3 className="title">Listenimport</h3>
    <hr className="hr"/>
    <Upload.Dragger
      name="file"
      action={api.invitation.invitationUploadEndpoint(props.meeting)}
      headers={{Authorization: `Token ${token}`}}
      onChange={onChange}
      maxCount={1}>
      <p>CSV</p>
    </Upload.Dragger>

    {infoResponse && <div style={{marginBottom: '20px'}}>
      <hr className="hr"/>
      <p>Eingefügt: {infoResponse.inserted}</p>
      <p>Aktualisiert: {infoResponse.updated}</p>
      {infoResponse.errors && <p><a onClick={()=>setIsModalVisible(true)}>Fehler: {infoResponse.errors}</a></p>}
    </div>}

  </div>
}

export default InvitationUpload;