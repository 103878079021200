import {
    formatDate,
    normalizeDateFields,
    normalizeOutDateFields
} from '../../utils/normalize';
import CRUD from '../../components/CRUD';
import api from '../../api';
import MeetingPlaceForm from './MeetingPlaceForm';

const MeetingPlaceList = ({parentId, parentName}) => {
    console.log(parentId, parentName);
    const columns = [
        {
            title: 'Datum',
            dataIndex: 'date_meeting',
            key: 'date_meeting',
            render: (text => {
               return formatDate(text)
            }),
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Ort',
            dataIndex: 'area',
            key: 'area',
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Teilnehmerzahl',
            dataIndex: 'count_of_members',
            key: 'count_of_members',
            sorter: (a, b) => a.name > b.name,
        },
    ];

    const normalizeFormData = (data) => {
        return normalizeDateFields(data, ['date_meeting']);
    }

    const normalizeSendData = (data) => {
        return normalizeOutDateFields(data, ['date_meeting']);
    }

    return <CRUD
        keyName="meeting-place"
        title="Liste Tagungsorte zur Tagung"
        FormComponent={MeetingPlaceForm}
        tableColumns={columns}
        apiList={api.meetings.meetingPlaceList}
        apiRetrieve={api.meetings.meetingPlaceRetrieve}
        apiCreate={api.meetings.meetingPlaceCreate}
        apiUpdate={api.meetings.meetingPlaceUpdate}
        apiDelete={api.meetings.meetingPlaceDelete}
        itemValueName="area"
        itemName="tagungsorte"
        parentField="meeting"
        parentId={parentId}
        parentName={parentName}
        normalizeFormData={normalizeFormData}
        normalizeSendData={normalizeSendData}
    />
};

export default MeetingPlaceList;
