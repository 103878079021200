import {DatePicker, Form, Input, InputNumber} from 'antd';

const MeetingParkingPassForm = ({form, onFinish}) => {
  return (
    <Form
      form={form}
      name="meetingEveningProgram"
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Name" name="name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Datum" name="date_program" rules={[{required: true}]}>
        <DatePicker format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item label='Kosten' initialValue={0} rules={[{required: true}]} name="price">
        <InputNumber addonAfter="€" style={{width: 100}}/>
      </Form.Item>
    </Form>
  );
};

export default MeetingParkingPassForm;
