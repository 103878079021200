import {Form, Input, Select} from 'antd';
import {useQuery} from 'react-query';
import api from '../../api';
import {greetings} from '../../constants';

const InvoiceForm = ({form, onFinish}) => {

  const {data: {data: statusList = []} = {}} = useQuery(['statuses'],
    async () => {
      return await api.meetings.statusList();
    }, {
      cacheTime: Infinity,
    });

  return (
    <Form
      form={form}
      name="InvoiceForm"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Anrede" name="greeting" rules={[{required: true}]}>
        <Select>
          {greetings?.map(item => <Select.Option key={item.value}
                                          value={item.value}>{item.label}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item label="Titel" name="title" rules={[{required: true}]}>
        <Input/>
      </Form.Item>

      <Form.Item label="Nachname" name="last_name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Vorname" name="first_name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="E-mail" name="email" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Teilnehmerstatus" name="status" rules={[{required: true}]}>
        <Select>
          {statusList.map(item => <Select.Option key={item.id} value={item.id}>
            {item.name}</Select.Option>)}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default InvoiceForm;
