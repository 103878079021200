import SimpleLayout from '../../components/SimpleLayout';
import {LogoComponent, footerText} from '../../containers/Common';

const EmptyLayout = ({breadCrumbs = [], children}) => {
  return (
    <SimpleLayout
      footerText={footerText}
      LogoComponent={LogoComponent}
    >{children}</SimpleLayout>
  );
}

export default EmptyLayout;
