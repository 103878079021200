import {Header} from 'antd/es/layout/layout';
import PropTypes from 'prop-types';
import {Layout} from 'antd';

const {Content, Footer} = Layout;

const SiderLayout = (
  {
    LogoComponent = () => <div className="logo"/>,
    footerText = '',
    children
  }) => {
  return (
    <Layout style={{minHeight: '100vh'}}>
      <Layout className="site-layout">
        <Header className="site-layout-background">
          <LogoComponent/>
        </Header>
        <Content>
          <div className="site-layout-content">
            <div className="site-layout-background"
                 style={{marginTop:24, padding: 24, minHeight: 360}}>
              {children}
            </div>
          </div>
        </Content>
        <Footer style={{textAlign: 'center'}}>{footerText}</Footer>
      </Layout>
    </Layout>
  );
}

SiderLayout.propTypes = {
  LogoComponent: PropTypes.elementType,
  footerText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export default SiderLayout;
