import {useState} from 'react';
import {MainLayout} from '../../containers/Common';
import MemberList from './MemberList';
import MemberSearch from './MemberSearch';
import MemberSummary from './MemberSummary';

const Member = () => {
  const [searchParams, setSearchParams] = useState({});

  const breadCrumbs = [
    {key: 'home', name: 'Gesamtübersicht ', url: '/'}
  ];

  return <MainLayout breadCrumbs={breadCrumbs}>
    <MemberSearch searchParams={searchParams} setSearchParams={setSearchParams}/>
    {searchParams.meeting && <MemberSummary searchParams={searchParams}/>}
    {searchParams.meeting && <MemberList searchParams={searchParams}/>}
  </MainLayout>;
}

export default Member;
