import {useState} from 'react';
import {MainLayout} from '../../containers/Common';
import ReportSearch from './ReportSearch';
import ReportsLinks from './ReportsLinks';

const Reports = () => {
  const [searchParams, setSearchParams] = useState({});

  const breadCrumbs = [
    {key: 'home', name: 'Gesamtübersicht ', url: '/'},
    {key: 'reports', name: 'Rechnung / Exporte'},
  ];

  return <MainLayout breadCrumbs={breadCrumbs}>
    <ReportSearch searchParams={searchParams} setSearchParams={setSearchParams}/>
    {searchParams.meeting && <ReportsLinks searchParams={searchParams}/>}
  </MainLayout>;
}

export default Reports;
