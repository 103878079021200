import {useState} from 'react';
import {MainLayout} from '../Common';
import InvoiceList from './InvoiceList';
import InvoiceSearch from './InvoiceSearch';

const State = () => {
  const [searchParams, setSearchParams] = useState({});

  const breadCrumbs = [
    {key: 'invoice', name: 'Rechnungen'}
  ];

  return <MainLayout breadCrumbs={breadCrumbs}>
    <InvoiceSearch searchParams={searchParams} setSearchParams={setSearchParams}/>
    {searchParams.meeting && <InvoiceList searchParams={searchParams}/>}
  </MainLayout>;
};

export default State;